<template>
  <section>
    <v-flex xs12 class="ctk-top-banner">
      <v-img
        v-if="info.banners[0]"
        :src="info.banners[0].img"
        class="ctk-top-banner"
        >
          <v-layout wrap hidden-sm-and-down>
            <v-flex md6></v-flex>
            <v-flex md6 class="banner-right-div">
              <div class="banner-text-div">
                <p class="banner-text-en">COMPANY PROFILE</p>
                <div class="banner-text-line"></div>
                <p class="banner-text-zh">了解好睡眠</p>
              </div>
            </v-flex>
          </v-layout>
        </v-img>
    </v-flex>
    <div class="div-1280 top-breadcrumb">
      <v-layout wrap>
        <v-flex md6 class="breadcrumb-left hidden-sm-and-down">了解好睡眠</v-flex>
        <v-flex md6><v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs></v-flex>
      </v-layout>
    </div>
    <div class="div-1280 ctk-container">
      <div class="ctk-page-title wow fadeInUp" id="introduce">{{intro.title}}</div>
      <div class="ctk-page-desc wow fadeInUp" data-wow-delay="0.5s">{{intro.desc}}</div>
      <div class="d-flex flex-wrap justify-space-between">
        <v-card v-for="item in intro.items" :key="item.key" flat tile :class="'intro-card intro-card' + item.key">
            <div class="intro-img wow fadeInUp"><img :src="'https://ctk-website.oss-cn-shanghai.aliyuncs.com/hsm3/about/intro' + item.key + '.png'"/></div>
            <div class="intro-title wow fadeInUp" data-wow-delay="0.5s">{{item.title}}</div>
            <div class="intro-desc wow fadeInUp" data-wow-delay="1s">{{item.desc}}</div>
        </v-card>
      </div>
    </div>
    <div class="div-1280 ctk-container" id="history">
      <div class="ctk-page-title wow fadeInUp">发展历程</div>
      <template>
        <swiper class="history-swiper" :options="swiperOption" v-if="timeline.length">
          <swiper-slide v-for="item in timeline" :key="item.ymd">
            <div class="history-top">
              <div class="history-ymd wow fadeInUp">{{item.ymd}}</div>
              <div class="history-title wow fadeInUp">{{item.title}}</div>
              <div class="history-desc wow fadeInUp">{{item.desc}}</div>
            </div>
            <div class="history-img wow fadeInUp"><img :src="item.img"/></div>
          </swiper-slide>
          <swiper-slide v-if="!isMobile"></swiper-slide>
          <swiper-slide v-if="!isMobile"></swiper-slide>
          <!-- <div class="swiper-pagination" slot="pagination"></div> -->
          <div class="swiper-button-prev" slot="button-prev"><i aria-hidden="true" class="v-icon notranslate mdi mdi-chevron-left" style="color: #FFF; font-size: 32px;"></i></div>
          <div class="swiper-button-next" slot="button-next"><i aria-hidden="true" class="v-icon notranslate mdi mdi-chevron-right" style="color: #FFF; font-size: 32px;"></i></div>
        </swiper>
      </template>
    </div>
    <div id="responsibility">
      <div class="div-1280 ctk-container">
        <div class="resp-page-title wow fadeInUp" id="introduce">{{resp.title}}</div>
        <v-row no-gutters v-for="item in resp.items" :key="item.key" class="resp-row hidden-sm-and-down">
          <template v-if="item.key % 2">
            <v-col cols="12" sm="12" md="5" class="resp-col-text">
              <div>
                <div class="resp-title wow fadeInUp">{{item.title}}</div>
                <div class="resp-desc wow fadeInUp" data-wow-delay="0.5s">{{item.desc}}</div>
                <div class="resp-remark wow fadeInUp" v-if="item.remark" v-html="item.remark"></div>
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="7" class="pl-md-5">
              <div class="resp-img wow fadeIn"><img :src="'https://ctk-website.oss-cn-shanghai.aliyuncs.com/hsm3/about/resp' + item.key + '.png'"/></div>
            </v-col>
          </template>
          <template v-else>
            <v-col cols="12" sm="12" md="7" class="pr-md-5">
              <div class="resp-img wow fadeIn"><img :src="'https://ctk-website.oss-cn-shanghai.aliyuncs.com/hsm3/about/resp' + item.key + '.png'"/></div>
            </v-col>
            <v-col cols="12" sm="12" md="5" class="resp-col-text">
              <div>
                <div class="resp-title wow fadeInUp">{{item.title}}</div>
                <div class="resp-desc wow fadeInUp" data-wow-delay="0.5s">{{item.desc}}</div>
                <div class="resp-remark wow fadeInUp" v-if="item.remark" v-html="item.remark"></div>
              </div>
            </v-col>
          </template>
        </v-row>
        <v-row no-gutters v-for="item in resp.items" :key="item.key" class="resp-row hidden-md-and-up">
          <v-col cols="12" sm="12" md="5" class="resp-col-text">
            <div>
              <div class="resp-title wow fadeInUp">{{item.title}}</div>
              <div class="resp-desc wow fadeInUp" data-wow-delay="0.5s">{{item.desc}}</div>
              <div class="resp-remark wow fadeInUp" v-if="item.remark" v-html="item.remark"></div>
            </div>
          </v-col>
          <v-col cols="12" sm="12" md="7" class="pl-md-5">
            <div class="resp-img wow fadeIn"><img :src="'https://ctk-website.oss-cn-shanghai.aliyuncs.com/hsm3/about/resp' + item.key + '.png'"/></div>
          </v-col>
        </v-row>
      </div>
    </div>
  </section>
</template>
<script>
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper/dist/vue-awesome-swiper.js'
export default {
  components: {
    swiper,
    swiperSlide
  },
  data: () => ({
    isMobile: false,
    pageId: 16,
    innerWidth: window.innerWidth,
    breadcrumbs: [
      { text: '首页', disabled: false, href: '/' },
      { text: '了解好睡眠', disabled: true }
    ],
    intro: {
      title: '企业介绍',
      desc: '好睡眠创立于2015年，隶属于中国建筑及装饰领域知名企业德硅集团（Citiking Group），专注于改善中国家庭睡眠环境，只为国民好睡眠而存在。',
      items: [
        { key: 1, title: '使命：只为国民好睡眠而存在。', desc: '以科技、专业、服务提供放心的睡眠环境功能改善解决方案。' },
        { key: 2, title: '愿景：让人人拥有好睡眠。', desc: '成为受全球用户信赖和选择的睡眠环境功能改善服务商。' },
        { key: 3, title: '价值观：低碳生活，健康睡眠。', desc: '开拓创新，专业至上，尽心服务，追求卓越。' }
      ]
    },
    timeline: [],
    swiperOption: {
      // centeredSlides: true,
      slidesPerView: 3,
      loop: false,
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
    },
    resp: {
      title: '社会责任',
      items: [
        { key: 1, title: '改善国民睡眠环境', desc: '中国睡眠研究会在2021年调查数据显示：超过3亿人存在睡眠障碍，成年人失眠发生率高达38.2%  。好睡眠生态集睡眠环境学、人体工程学、智能化技术，改善影响睡眠的静环境、暗环境、眠环境和嗅环境四大功能，全时段动态监测守护睡眠健康，以科技、专业、服务提供睡眠环境功能改善解决方案。为用户提供领先的“三无”产品、“三无”服务，打造24小时全天候深睡环境，让人人拥有好睡眠。', remark: null },
        { key: 2, title: '低碳生活，健康睡眠', desc: '全球变暖是人类的行为造成地球气候变化的后果。“碳”耗用得多，导致地球暖化的元凶“二氧化碳”也制造得多。我们每天居住生活的家，在使用过程中消耗大量能源，其中采暖空调能耗大约占建筑总能耗的65%左右*，对环境可持续发展造成负担。提高建筑围护结构保温隔热性能，是家庭建筑节能主要改善方向。好睡眠从成立之初始终坚持“低碳生活，健康睡眠”的理念，前瞻性地在行业提出睡眠环境功能改善解决方案，可有效提高房间保温隔热能力，最高可降低家庭建筑能耗75%*，降低日常电费等能耗费用支出25%左右*。助力中国家庭打造低碳睡眠空间，为中国在2030年实现碳达峰、2060年实现碳中和积极承担力所能及的企业社会责任。', remark: '*数据来源：中国政府网<br/>*数据来源：好睡眠理论测算，实际数据效果可能跟家庭建筑实际情况有所差异。' },
        { key: 3, title: '辅助性降低睡眠障碍性疾病发生率，为国家卫生投入减负', desc: '睡眠障碍不仅仅是个人的事，更是公共卫生问题。科学研究显示，睡眠障碍性疾病与脑血管疾病、心脏疾病及恶性肿瘤等直接相关。及时有效地治疗睡眠疾病，不仅可以使患者直接受益，还能明显降低国家卫生投入。好睡眠睡眠环境解决方案通过改善影响睡眠的静环意、暗环境、眠环境和嗅环境四大功能，助力用户实现高质量睡眠。消除的是工作疲劳，降低的则是睡眠障碍性疾病的发生率，同时为国家卫生投入减负起到积极的推进作用。从国家战略层面的疾病预防科学，将提高国民健康体质作为一种核心竞争力！', remark: null }
      ]
    }
  }),
  created () {
    document.title = '了解好睡眠|企业介绍|发展历程|社会责任-好睡眠'
    document.querySelector('#menu_about').classList.add('v-btn--active')
    if (window.innerWidth < 960) {
      this.isMobile = true
      this.swiperOption.slidesPerView = 1
    }
    this.getAbout()
  },
  mounted () {
    this.$wow.init()
    let hash = location.hash.slice(1)
    if (hash) {
      this.goAnchor(hash)
    }
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
        this.info.banners = val.banner[this.pageId]
      },
      deep: true
    }
  },
  computed: {
    info () {
      let banners = {}
      banners[this.pageId] = []
      if (this.$store.getters.companyInfo.banner) {
        banners = this.$store.getters.companyInfo.banner[this.pageId]
      }
      return {
        banners: banners
      }
    }
  },
  methods: {
    delHtmlTag (str) {
      return str.replace(/<[^>]+>/g, '')
    },
    getAbout () {
      let that = this
      this.https.get('about').then(response => {
        if (response.code === 0) {
          let rs = response.data.timeline
          rs.reverse()
          let timeline = []
          rs.forEach(function (e) {
            e.ymd = e.ymd.substring(0, 7)
            e.desc = that.delHtmlTag(e.desc)
            timeline.push(e)
          })
          that.timeline = timeline
        }
      })
    },
    goAnchor (selector) {
      let el = document.getElementById(selector)
      if (el) {
        el.scrollIntoView(true)
      }
    }
  }
}
</script>
<style lang="css">
  @import '~@/assets/css/about.css';
</style>
